// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as M23$Linear from "lumi-linear/lib/es6/src/M23.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as Recti$Linear from "lumi-linear/lib/es6/src/Recti.bs.mjs";
import * as Radians$Linear from "lumi-linear/lib/es6/src/Radians.bs.mjs";

function fromRect(param) {
  var match = V2$Linear.map(param.pos, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(param.size, (function (prim) {
          return prim.toString();
        }));
  return match[0] + " " + match[1] + " " + match$1[0] + " " + match$1[1];
}

function fromSize(size) {
  return fromRect(Rectf$Linear.fromSize(size));
}

var Float = {
  fromRect: fromRect,
  fromSize: fromSize
};

function fromRect$1(param) {
  var match = V2$Linear.map(param.pos, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(param.size, (function (prim) {
          return prim.toString();
        }));
  return match[0] + " " + match[1] + " " + match$1[0] + " " + match$1[1];
}

function fromSize$1(size) {
  return fromRect$1(Recti$Linear.fromSize(size));
}

var Int = {
  fromRect: fromRect$1,
  fromSize: fromSize$1
};

var ViewBox = {
  Float: Float,
  Int: Int
};

function list(__x) {
  return __x.join(" ");
}

function fromM23f(mat) {
  var match = M23$Linear.map(mat, (function (prim) {
          return prim.toString();
        }));
  var match$1 = match[1];
  var match$2 = match[0];
  return "matrix(" + match$2[0] + " " + match$1[0] + " " + match$2[1] + " " + match$1[1] + " " + match$2[2] + " " + match$1[2] + ")";
}

function fromM33f(param) {
  return fromM23f([
              param[0],
              param[1]
            ]);
}

var Matrix = {
  fromM23f: fromM23f,
  fromM33f: fromM33f
};

function skewX(angle) {
  var a = angle.toString();
  return "skewX(" + a + ")";
}

function skewY(angle) {
  var a = angle.toString();
  return "skewY(" + a + ")";
}

function rotate(angle) {
  var a = angle.toString();
  return "rotate(" + a + ")";
}

var Degree = {
  skewX: skewX,
  skewY: skewY,
  rotate: rotate
};

function translate(vec) {
  var match = V2$Linear.map(vec, (function (prim) {
          return prim.toString();
        }));
  return "translate(" + match[0] + " " + match[1] + ")";
}

function scale(vec) {
  var match = V2$Linear.map(vec, (function (prim) {
          return prim.toString();
        }));
  return "scale(" + match[0] + " " + match[1] + ")";
}

function rotateAround(angle, around) {
  var a = angle.toString();
  var match = V2$Linear.map(around, (function (prim) {
          return prim.toString();
        }));
  return "rotate(" + a + " " + match[0] + " " + match[1] + ")";
}

var Degree$1 = {
  rotateAround: rotateAround
};

function rotateAround$1(angle, around) {
  return rotateAround(Radians$Linear.toDegrees(angle), around);
}

var Radian = {
  rotateAround: rotateAround$1
};

var Float$1 = {
  translate: translate,
  scale: scale,
  Degree: Degree$1,
  Radian: Radian
};

var Transform = {
  list: list,
  Matrix: Matrix,
  Degree: Degree,
  Float: Float$1
};

var $$DOMMatrix = {};

var $$SVGPoint = {};

var $$SVGGraphicsElement = {};

function screenSpaceToViewportCoordinates(svgElement, param) {
  var screenSpacePoint = svgElement.createSVGPoint();
  screenSpacePoint.x = param[0];
  screenSpacePoint.y = param[1];
  var screenSpaceToViewportSpace = svgElement.getScreenCTM().inverse();
  var viewportSpacePoint = screenSpacePoint.matrixTransform(screenSpaceToViewportSpace);
  return [
          viewportSpacePoint.x,
          viewportSpacePoint.y
        ];
}

function mouseEventToViewportCoordinates($$event, svgElement) {
  return screenSpaceToViewportCoordinates(svgElement, [
              $$event.clientX,
              $$event.clientY
            ]);
}

export {
  ViewBox ,
  Transform ,
  $$DOMMatrix ,
  $$SVGPoint ,
  $$SVGGraphicsElement ,
  screenSpaceToViewportCoordinates ,
  mouseEventToViewportCoordinates ,
}
/* Rectf-Linear Not a pure module */
