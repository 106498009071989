// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as V2i$Linear from "lumi-linear/lib/es6/src/V2i.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as PlaceClass$LumiDocuments from "./Place/PlaceClass.bs.mjs";
import * as PlaceModel$LumiDocuments from "./Place/PlaceModel.bs.mjs";
import * as RightAngle$LumiDocuments from "./Types/RightAngle.bs.mjs";

var codec = Jzon.object7((function (param) {
        return [
                param.occupation,
                param.timestamp,
                param.imgBlob,
                param.imgResolution,
                param.imgRotation,
                param.placeClass,
                param.placeModel
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  occupation: param[0],
                  timestamp: param[1],
                  imgBlob: param[2],
                  imgResolution: param[3],
                  imgRotation: param[4],
                  placeClass: param[5],
                  placeModel: param[6]
                }
              };
      }), Jzon.field("occupation", Document$LumiStore.Key.codec()), Jzon.field("timestamp", LumiDate.DateTime.codec), Jzon.field("imgBlob", Document$LumiStore.Key.codec()), Jzon.field("imgResolution", V2i$Linear.codec), Jzon.field("imgRotation", RightAngle$LumiDocuments.RightAngle.codec), Jzon.field("placeClass", PlaceClass$LumiDocuments.PlaceClass.codec), Jzon.field("placeModel", PlaceModel$LumiDocuments.PlaceModel.codec));

var OccupationSnapshot = {
  codec: codec
};

function documentName(param) {
  
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "magnifying-glass"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "occupation-snapshot",
      codec: codec,
      icon: icon
    });

export {
  OccupationSnapshot ,
  Schema ,
}
/* codec Not a pure module */
