// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";

var codec = Jzon.object2((function (param) {
        return [
                param.capacity,
                param.modbusTcpConverter
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  capacity: param[0],
                  modbusTcpConverter: param[1]
                }
              };
      }), Jzon.field("capacity", Jzon.$$int), Jzon.field("modbusTcpConverter", Document$LumiStore.Key.codec()));

var StJansdalRoofSensor = {
  codec: codec
};

var codec$1 = LumiJzon.taggedVariantOptionalContents((function (value) {
        if (typeof value !== "object") {
          return [
                  "ManagedSensorType",
                  undefined
                ];
        }
        switch (value.TAG) {
          case "PlaceSensorType" :
              return [
                      "PlaceSensorType",
                      Jzon.encodeWith(value._0, Document$LumiStore.Key.codec())
                    ];
          case "UltrasonicSensorType" :
              return [
                      "UltrasonicSensorType",
                      Jzon.encodeWith(value._0, Document$LumiStore.Key.codec())
                    ];
          case "StJansdalRoofSensorType" :
              return [
                      "StJansdalRoofSensorType",
                      Jzon.encodeWith(value._0, codec)
                    ];
          
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "ManagedSensorType" :
              return {
                      TAG: "Ok",
                      _0: "ManagedSensorType"
                    };
          case "PlaceSensorType" :
              return Core__Result.map(Core__Result.flatMap(LumiOption.okOr(json, {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    "content missing"
                                  ]
                                }), (function (extra) {
                                return Jzon.decode(Document$LumiStore.Key.codec(), extra);
                              })), (function (placeKey) {
                            return {
                                    TAG: "PlaceSensorType",
                                    _0: placeKey
                                  };
                          }));
          case "StJansdalRoofSensorType" :
              return Core__Result.map(Core__Result.flatMap(LumiOption.okOr(json, {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    "content missing"
                                  ]
                                }), (function (extra) {
                                return Jzon.decode(codec, extra);
                              })), (function (stJansdalRoofSensor) {
                            return {
                                    TAG: "StJansdalRoofSensorType",
                                    _0: stJansdalRoofSensor
                                  };
                          }));
          case "UltrasonicSensorType" :
              return Core__Result.map(Core__Result.flatMap(LumiOption.okOr(json, {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    "content missing"
                                  ]
                                }), (function (extra) {
                                return Jzon.decode(Document$LumiStore.Key.codec(), extra);
                              })), (function (ultrasonicSensorKey) {
                            return {
                                    TAG: "UltrasonicSensorType",
                                    _0: ultrasonicSensorKey
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var SensorSubType = {
  codec: codec$1
};

var codec$2 = Jzon.object4((function (param) {
        return [
                param.name,
                param.description,
                param.subType,
                param.centralId
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  description: param[1],
                  subType: param[2],
                  centralId: param[3]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("description", Jzon.string), Jzon.field("subType", codec$1), Jzon.field("centralId", SensorGroups$LumiStaticData.SensorFullId.codec));

var Sensor = {
  codec: codec$2
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "chart-line"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "sensor",
      codec: codec$2,
      icon: icon
    });

export {
  StJansdalRoofSensor ,
  SensorSubType ,
  Sensor ,
  Schema ,
}
/* codec Not a pure module */
