// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V3$Linear from "./V3.bs.mjs";

var zero = V3$Linear.fill(0);

function plus(param, param$1) {
  return [
          param[0] + param$1[0],
          param[1] + param$1[1],
          param[2] + param$1[2]
        ];
}

function minus(param, param$1) {
  return [
          param[0] - param$1[0],
          param[1] - param$1[1],
          param[2] - param$1[2]
        ];
}

function scale(vec, factor) {
  return V3$Linear.map(vec, (function (v) {
                return v * factor;
              }));
}

function dot(param, param$1) {
  return param[0] * param$1[0] + param[1] * param$1[1] + param[2] * param$1[2];
}

function length(vec) {
  return Math.sqrt(dot(vec, vec));
}

function angle(a, b) {
  return Math.acos(dot(a, b) / (length(a) * length(b)));
}

var codec = V3$Linear.codec(Jzon.$$float);

export {
  zero ,
  plus ,
  minus ,
  scale ,
  dot ,
  length ,
  angle ,
  codec ,
}
/* zero Not a pure module */
