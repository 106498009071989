// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";

function get(storable) {
  return Core__Option.getOr(Core__Option.flatMap(Caml_option.nullable_to_opt(localStorage.getItem(storable.key)), (function (str) {
                    return storable.codec.fromString(str);
                  })), storable.default);
}

function set(storable, value) {
  localStorage.setItem(storable.key, storable.codec.toString(value));
}

function remove(prim) {
  localStorage.removeItem(prim);
}

function clear(prim) {
  localStorage.clear();
}

export {
  remove ,
  clear ,
  get ,
  set ,
}
/* No side effect */
