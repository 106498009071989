// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Locale$LumiI18n from "./Locale.bs.mjs";

function MakeProvider(Params) {
  var string = function (locale, entry) {
    return Params.translate(locale, entry);
  };
  var Translation$MakeProvider$Text = function (props) {
    var locale = Locale$LumiI18n.useContext();
    return Params.translate(locale, props.entry);
  };
  var $$Text = {
    string: string,
    make: Translation$MakeProvider$Text
  };
  return {
          translate: Params.translate,
          $$Text: $$Text
        };
}

export {
  MakeProvider ,
}
/* Locale-LumiI18n Not a pure module */
