// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V3$Linear from "./V3.bs.mjs";
import * as M33$Linear from "./M33.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";
import * as V3f$Linear from "./V3f.bs.mjs";
import * as M22f$Linear from "./M22f.bs.mjs";

var zero = M33$Linear.fill(0);

function scalar(value) {
  return [
          [
            value,
            0,
            0
          ],
          [
            0,
            value,
            0
          ],
          [
            0,
            0,
            value
          ]
        ];
}

var identity = scalar(1);

function scale(m, s) {
  return M33$Linear.map(m, (function (e) {
                return e * s;
              }));
}

function determinant(param) {
  var match = param[2];
  var i = match[2];
  var h = match[1];
  var match$1 = param[1];
  var f = match$1[2];
  var e = match$1[1];
  var match$2 = param[0];
  var c = match$2[2];
  var b = match$2[1];
  return match$2[0] * (e * i - f * h) - match$1[0] * (b * i - c * h) + match[0] * (b * f - c * e);
}

function inverse(m) {
  var cofactor = function (q, r, s, t) {
    return M22f$Linear.determinant([
                [
                  q,
                  r
                ],
                [
                  s,
                  t
                ]
              ]);
  };
  var match = m[2];
  var i = match[2];
  var h = match[1];
  var g = match[0];
  var match$1 = m[1];
  var f = match$1[2];
  var e = match$1[1];
  var d = match$1[0];
  var match$2 = m[0];
  var c = match$2[2];
  var b = match$2[1];
  var a = match$2[0];
  var a$p = cofactor(e, f, h, i);
  var b$p = cofactor(c, b, i, h);
  var c$p = cofactor(b, c, e, f);
  var d$p = cofactor(f, d, i, g);
  var e$p = cofactor(a, c, g, i);
  var f$p = cofactor(c, a, f, d);
  var g$p = cofactor(d, e, g, h);
  var h$p = cofactor(b, a, h, g);
  var i$p = cofactor(a, b, d, e);
  return scale([
              [
                a$p,
                b$p,
                c$p
              ],
              [
                d$p,
                e$p,
                f$p
              ],
              [
                g$p,
                h$p,
                i$p
              ]
            ], 1 / determinant(m));
}

function v3f(a, b) {
  return V3$Linear.map(a, (function (row) {
                return V3f$Linear.dot(row, b);
              }));
}

function m33f(a, b) {
  var bcols = M33$Linear.transpose(b);
  return V3$Linear.map(a, (function (row) {
                return V3$Linear.map(bcols, (function (col) {
                              return V3f$Linear.dot(row, col);
                            }));
              }));
}

var Mult = {
  v3f: v3f,
  m33f: m33f
};

function rotation(angle) {
  return [
          [
            Math.cos(angle),
            -1 * Math.sin(angle),
            0
          ],
          [
            Math.sin(angle),
            Math.cos(angle),
            0
          ],
          [
            0,
            0,
            1
          ]
        ];
}

function scale$1(param) {
  return [
          [
            param[0],
            0,
            0
          ],
          [
            0,
            param[1],
            0
          ],
          [
            0,
            0,
            1
          ]
        ];
}

function scaleUniform(s) {
  return scale$1([
              s,
              s
            ]);
}

function translation(param) {
  return [
          [
            1,
            0,
            param[0]
          ],
          [
            0,
            1,
            param[1]
          ],
          [
            0,
            0,
            1
          ]
        ];
}

function addTranslation(param, param$1) {
  var match = param[2];
  var match$1 = param[1];
  var match$2 = param[0];
  return [
          [
            match$2[0],
            match$2[1],
            match$2[2] + param$1[0]
          ],
          [
            match$1[0],
            match$1[1],
            match$1[2] + param$1[1]
          ],
          [
            match[0],
            match[1],
            match[2]
          ]
        ];
}

function rotationAround(angle, point) {
  return m33f(m33f(translation(point), rotation(angle)), translation(V2f$Linear.scale(point, -1)));
}

var Transformation = {
  rotation: rotation,
  scale: scale$1,
  scaleUniform: scaleUniform,
  translation: translation,
  addTranslation: addTranslation,
  rotationAround: rotationAround
};

var codec = M33$Linear.codec(Jzon.$$float);

export {
  zero ,
  scalar ,
  identity ,
  scale ,
  determinant ,
  inverse ,
  Mult ,
  Transformation ,
  codec ,
}
/* zero Not a pure module */
