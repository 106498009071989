// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var codec = Jzon.object6((function (param) {
        return [
                param.widthFront,
                param.widthBack,
                param.heightFront,
                param.heightBack,
                param.baseFrontExtra,
                param.baseBackExtra
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  widthFront: param[0],
                  widthBack: param[1],
                  heightFront: param[2],
                  heightBack: param[3],
                  baseFrontExtra: param[4],
                  baseBackExtra: param[5]
                }
              };
      }), Jzon.field("widthFront", Jzon.$$float), Jzon.field("widthBack", Jzon.$$float), Jzon.field("heightFront", Jzon.$$float), Jzon.field("heightBack", Jzon.$$float), Jzon.field("baseFrontExtra", Jzon.$$float), Jzon.field("baseBackExtra", Jzon.$$float));

var AxesModelDimensions = {
  codec: codec
};

var codec$1 = Jzon.custom((function (x) {
        var tmp;
        switch (x) {
          case "VehicleBicycle" :
              tmp = "VehicleBicycle";
              break;
          case "VehicleScooter" :
              tmp = "VehicleScooter";
              break;
          case "VehicleCar" :
              tmp = "VehicleCar";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (res) {
                      switch (res) {
                        case "VehicleBicycle" :
                            return {
                                    TAG: "Ok",
                                    _0: "VehicleBicycle"
                                  };
                        case "VehicleCar" :
                            return {
                                    TAG: "Ok",
                                    _0: "VehicleCar"
                                  };
                        case "VehicleScooter" :
                            return {
                                    TAG: "Ok",
                                    _0: "VehicleScooter"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      res
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var VehicleClass = {
  codec: codec$1
};

var codec$2 = Jzon.custom((function (x) {
        var tmp;
        switch (x) {
          case "GutterUpper" :
              tmp = "GutterUpper";
              break;
          case "GutterLower" :
              tmp = "GutterLower";
              break;
          case "GutterAngled" :
              tmp = "GutterAngled";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (res) {
                      switch (res) {
                        case "GutterAngled" :
                            return {
                                    TAG: "Ok",
                                    _0: "GutterAngled"
                                  };
                        case "GutterLower" :
                            return {
                                    TAG: "Ok",
                                    _0: "GutterLower"
                                  };
                        case "GutterUpper" :
                            return {
                                    TAG: "Ok",
                                    _0: "GutterUpper"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      res
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var GutterClass = {
  codec: codec$2
};

var codec$3 = Jzon.custom((function (x) {
        var tmp;
        switch (x) {
          case "StapleRackLeftOf" :
              tmp = "StapleRackLeftOf";
              break;
          case "StapleRackRightOf" :
              tmp = "StapleRackRightOf";
              break;
          case "StapleRackBetween" :
              tmp = "StapleRackBetween";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (res) {
                      switch (res) {
                        case "StapleRackBetween" :
                            return {
                                    TAG: "Ok",
                                    _0: "StapleRackBetween"
                                  };
                        case "StapleRackLeftOf" :
                            return {
                                    TAG: "Ok",
                                    _0: "StapleRackLeftOf"
                                  };
                        case "StapleRackRightOf" :
                            return {
                                    TAG: "Ok",
                                    _0: "StapleRackRightOf"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      res
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var StapleRack = {
  codec: codec$3
};

var values = [
  "PlaceClassUnclassified",
  "PlaceClassOther",
  {
    TAG: "PlaceClassGround",
    _0: "VehicleBicycle"
  },
  {
    TAG: "PlaceClassGround",
    _0: "VehicleScooter"
  },
  {
    TAG: "PlaceClassGround",
    _0: "VehicleCar"
  },
  {
    TAG: "PlaceClassGutter",
    _0: "GutterUpper"
  },
  {
    TAG: "PlaceClassGutter",
    _0: "GutterLower"
  },
  {
    TAG: "PlaceClassGutter",
    _0: "GutterAngled"
  },
  "PlaceClassWheelClamp",
  {
    TAG: "PlaceClassStapleRack",
    _0: "StapleRackLeftOf"
  },
  {
    TAG: "PlaceClassStapleRack",
    _0: "StapleRackRightOf"
  },
  {
    TAG: "PlaceClassStapleRack",
    _0: "StapleRackBetween"
  },
  "PlaceClassVertical",
  "PlaceClassHandlebarSuspension"
];

function toString(placeClass) {
  if (typeof placeClass !== "object") {
    switch (placeClass) {
      case "PlaceClassUnclassified" :
          return "Unclassified";
      case "PlaceClassOther" :
          return "Other";
      case "PlaceClassWheelClamp" :
          return "Wheel clamp";
      case "PlaceClassVertical" :
          return "Vertical";
      case "PlaceClassHandlebarSuspension" :
          return "Handlebar suspension";
      
    }
  } else {
    switch (placeClass.TAG) {
      case "PlaceClassGround" :
          switch (placeClass._0) {
            case "VehicleBicycle" :
                return "Ground bicycle";
            case "VehicleScooter" :
                return "Ground scooter";
            case "VehicleCar" :
                return "Ground car";
            
          }
      case "PlaceClassGutter" :
          switch (placeClass._0) {
            case "GutterUpper" :
                return "Gutter upper";
            case "GutterLower" :
                return "Gutter lower";
            case "GutterAngled" :
                return "Gutter angled";
            
          }
      case "PlaceClassStapleRack" :
          switch (placeClass._0) {
            case "StapleRackLeftOf" :
                return "Left of staple";
            case "StapleRackRightOf" :
                return "Right of staple";
            case "StapleRackBetween" :
                return "Between staples";
            
          }
      
    }
  }
}

function fromString(str) {
  switch (str) {
    case "Between staples" :
        return {
                TAG: "PlaceClassStapleRack",
                _0: "StapleRackBetween"
              };
    case "Ground bicycle" :
        return {
                TAG: "PlaceClassGround",
                _0: "VehicleBicycle"
              };
    case "Ground car" :
        return {
                TAG: "PlaceClassGround",
                _0: "VehicleCar"
              };
    case "Ground scooter" :
        return {
                TAG: "PlaceClassGround",
                _0: "VehicleScooter"
              };
    case "Gutter angled" :
        return {
                TAG: "PlaceClassGutter",
                _0: "GutterAngled"
              };
    case "Gutter lower" :
        return {
                TAG: "PlaceClassGutter",
                _0: "GutterLower"
              };
    case "Gutter upper" :
        return {
                TAG: "PlaceClassGutter",
                _0: "GutterUpper"
              };
    case "Handlebar suspension" :
        return "PlaceClassHandlebarSuspension";
    case "Left of staple" :
        return {
                TAG: "PlaceClassStapleRack",
                _0: "StapleRackLeftOf"
              };
    case "Other" :
        return "PlaceClassOther";
    case "Right of staple" :
        return {
                TAG: "PlaceClassStapleRack",
                _0: "StapleRackRightOf"
              };
    case "Unclassified" :
        return "PlaceClassUnclassified";
    case "Vertical" :
        return "PlaceClassVertical";
    case "Wheel clamp" :
        return "PlaceClassWheelClamp";
    default:
      return ;
  }
}

var codec$4 = LumiJzon.taggedVariantOptionalContents((function (x) {
        if (typeof x !== "object") {
          switch (x) {
            case "PlaceClassUnclassified" :
                return [
                        "PlaceClassUnclassified",
                        undefined
                      ];
            case "PlaceClassOther" :
                return [
                        "PlaceClassOther",
                        undefined
                      ];
            case "PlaceClassWheelClamp" :
                return [
                        "PlaceClassWheelClamp",
                        undefined
                      ];
            case "PlaceClassVertical" :
                return [
                        "PlaceClassVertical",
                        undefined
                      ];
            case "PlaceClassHandlebarSuspension" :
                return [
                        "PlaceClassHandlebarSuspension",
                        undefined
                      ];
            
          }
        } else {
          switch (x.TAG) {
            case "PlaceClassGround" :
                return [
                        "PlaceClassGround",
                        Jzon.encodeWith(x._0, codec$1)
                      ];
            case "PlaceClassGutter" :
                return [
                        "PlaceClassGutter",
                        Jzon.encodeWith(x._0, codec$2)
                      ];
            case "PlaceClassStapleRack" :
                return [
                        "PlaceClassStapleRack",
                        Jzon.encodeWith(x._0, codec$3)
                      ];
            
          }
        }
      }), (function (param) {
        var maybeContents = param[1];
        var tag = param[0];
        switch (tag) {
          case "PlaceClassGround" :
              if (maybeContents !== undefined) {
                return Core__Result.map(Jzon.decodeWith(maybeContents, codec$1), (function (res) {
                              return {
                                      TAG: "PlaceClassGround",
                                      _0: res
                                    };
                            }));
              }
              break;
          case "PlaceClassGutter" :
              if (maybeContents !== undefined) {
                return Core__Result.map(Jzon.decodeWith(maybeContents, codec$2), (function (res) {
                              return {
                                      TAG: "PlaceClassGutter",
                                      _0: res
                                    };
                            }));
              }
              break;
          case "PlaceClassHandlebarSuspension" :
              if (maybeContents === undefined) {
                return {
                        TAG: "Ok",
                        _0: "PlaceClassHandlebarSuspension"
                      };
              }
              break;
          case "PlaceClassOther" :
              if (maybeContents === undefined) {
                return {
                        TAG: "Ok",
                        _0: "PlaceClassOther"
                      };
              }
              break;
          case "PlaceClassStapleRack" :
              if (maybeContents !== undefined) {
                return Core__Result.map(Jzon.decodeWith(maybeContents, codec$3), (function (res) {
                              return {
                                      TAG: "PlaceClassStapleRack",
                                      _0: res
                                    };
                            }));
              }
              break;
          case "PlaceClassUnclassified" :
              if (maybeContents === undefined) {
                return {
                        TAG: "Ok",
                        _0: "PlaceClassUnclassified"
                      };
              }
              break;
          case "PlaceClassVertical" :
              if (maybeContents === undefined) {
                return {
                        TAG: "Ok",
                        _0: "PlaceClassVertical"
                      };
              }
              break;
          case "PlaceClassWheelClamp" :
              if (maybeContents === undefined) {
                return {
                        TAG: "Ok",
                        _0: "PlaceClassWheelClamp"
                      };
              }
              break;
          default:
            
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonValue",
                  VAL: [
                    [],
                    tag
                  ]
                }
              };
      }));

function PlaceClass$PlaceClass$Icon(props) {
  var placeClass = props.placeClass;
  if (typeof placeClass !== "object") {
    switch (placeClass) {
      case "PlaceClassUnclassified" :
          return JsxRuntime.jsx(FontAwesome.Solid.make, {
                      name: "question"
                    });
      case "PlaceClassOther" :
          return JsxRuntime.jsx(FontAwesome.Solid.make, {
                      name: "ellipsis"
                    });
      case "PlaceClassWheelClamp" :
          return JsxRuntime.jsx(FontAwesome.Solid.make, {
                      name: "wrench"
                    });
      case "PlaceClassVertical" :
          return JsxRuntime.jsx(FontAwesome.Solid.make, {
                      name: "bicycle",
                      rotate: -90
                    });
      case "PlaceClassHandlebarSuspension" :
          return JsxRuntime.jsx(FontAwesome.Solid.make, {
                      name: "bicycle",
                      rotate: -22.5
                    });
      
    }
  } else {
    switch (placeClass.TAG) {
      case "PlaceClassGround" :
          switch (placeClass._0) {
            case "VehicleBicycle" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "bicycle"
                          });
            case "VehicleScooter" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "motorcycle"
                          });
            case "VehicleCar" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "car"
                          });
            
          }
      case "PlaceClassGutter" :
          switch (placeClass._0) {
            case "GutterUpper" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "right-from-bracket",
                            rotate: -90
                          });
            case "GutterLower" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "right-to-bracket",
                            rotate: 90
                          });
            case "GutterAngled" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "grip-lines",
                            rotate: -22.5
                          });
            
          }
      case "PlaceClassStapleRack" :
          switch (placeClass._0) {
            case "StapleRackLeftOf" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "arrow-left"
                          });
            case "StapleRackRightOf" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "arrow-right"
                          });
            case "StapleRackBetween" :
                return JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "arrow-turn-down"
                          });
            
          }
      
    }
  }
}

var Icon = {
  make: PlaceClass$PlaceClass$Icon
};

function toClassName(placeClass) {
  if (typeof placeClass !== "object") {
    switch (placeClass) {
      case "PlaceClassUnclassified" :
          return "place-class-unclassified";
      case "PlaceClassOther" :
          return "place-class-other";
      case "PlaceClassWheelClamp" :
          return "place-class-wheel-clamp";
      case "PlaceClassVertical" :
          return "place-class-vertical";
      case "PlaceClassHandlebarSuspension" :
          return "place-class-handlebar-suspension";
      
    }
  } else {
    switch (placeClass.TAG) {
      case "PlaceClassGround" :
          switch (placeClass._0) {
            case "VehicleBicycle" :
                return "place-class-ground-bicycle";
            case "VehicleScooter" :
                return "place-class-ground-scooter";
            case "VehicleCar" :
                return "place-class-ground-car";
            
          }
      case "PlaceClassGutter" :
          switch (placeClass._0) {
            case "GutterUpper" :
                return "place-class-gutter-upper";
            case "GutterLower" :
                return "place-class-gutter-lower";
            case "GutterAngled" :
                return "place-class-gutter-angled";
            
          }
      case "PlaceClassStapleRack" :
          switch (placeClass._0) {
            case "StapleRackLeftOf" :
                return "place-class-staple-rack-left-of";
            case "StapleRackRightOf" :
                return "place-class-staple-rack-right-of";
            case "StapleRackBetween" :
                return "place-class-staple-rack-between";
            
          }
      
    }
  }
}

function axesModelDimensions(placeClass) {
  if (typeof placeClass !== "object") {
    return {
            widthFront: 0.500,
            widthBack: 0.400,
            heightFront: 0.750,
            heightBack: 0.600,
            baseFrontExtra: 0.400,
            baseBackExtra: 0.000
          };
  }
  if (placeClass.TAG !== "PlaceClassGround") {
    return {
            widthFront: 0.500,
            widthBack: 0.400,
            heightFront: 0.750,
            heightBack: 0.600,
            baseFrontExtra: 0.400,
            baseBackExtra: 0.000
          };
  }
  switch (placeClass._0) {
    case "VehicleBicycle" :
    case "VehicleScooter" :
        return {
                widthFront: 0.500,
                widthBack: 0.400,
                heightFront: 0.750,
                heightBack: 0.600,
                baseFrontExtra: 0.400,
                baseBackExtra: 0.000
              };
    case "VehicleCar" :
        return {
                widthFront: 2.000,
                widthBack: 1.500,
                heightFront: 0.400,
                heightBack: 0.200,
                baseFrontExtra: 0.000,
                baseBackExtra: 0.500
              };
    
  }
}

function quadrilateralModelHeight(placeClass) {
  if (typeof placeClass !== "object") {
    return 1.000;
  }
  if (placeClass.TAG !== "PlaceClassGround") {
    return 1.000;
  }
  switch (placeClass._0) {
    case "VehicleBicycle" :
    case "VehicleScooter" :
        return 1.000;
    case "VehicleCar" :
        return 1.500;
    
  }
}

var PlaceClass = {
  values: values,
  toString: toString,
  fromString: fromString,
  codec: codec$4,
  Icon: Icon,
  toClassName: toClassName,
  axesModelDimensions: axesModelDimensions,
  quadrilateralModelHeight: quadrilateralModelHeight
};

export {
  AxesModelDimensions ,
  VehicleClass ,
  GutterClass ,
  StapleRack ,
  PlaceClass ,
}
/* codec Not a pure module */
