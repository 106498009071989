// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as M22f$Linear from "lumi-linear/lib/es6/src/M22f.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as PlaceClass$LumiDocuments from "./PlaceClass.bs.mjs";

import '../../../../src/css/place-model.sass'
;

function transform(param, hmirror, vflip, param$1) {
  var height = param[1];
  var width = param[0];
  var transformAbs = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? width - x : x,
            vflip ? height - y : y
          ];
  };
  var transformRel = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? -1 * x : x,
            vflip ? -1 * y : y
          ];
  };
  return {
          baseFront: transformAbs(param$1.baseFront),
          baseBack: transformAbs(param$1.baseBack),
          up: Core__Option.map(param$1.up, transformRel),
          side: transformAbs(param$1.side),
          sideLength: param$1.sideLength
        };
}

var codec = Jzon.object5((function (param) {
        return [
                param.baseFront,
                param.baseBack,
                param.up,
                param.side,
                param.sideLength
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  baseFront: param[0],
                  baseBack: param[1],
                  up: param[2],
                  side: param[3],
                  sideLength: param[4]
                }
              };
      }), Jzon.field("baseFront", V2f$Linear.codec), Jzon.field("baseBack", V2f$Linear.codec), Jzon.field("up", Jzon.nullable(V2f$Linear.codec)), Jzon.field("side", V2f$Linear.codec), Jzon.field("sideLength", Jzon.$$float));

function getScale(param) {
  return V2f$Linear.length(V2f$Linear.minus(param.side, param.baseFront)) / param.sideLength;
}

function toPoly(placeModel, placeClass) {
  var up = placeModel.up;
  var baseBack = placeModel.baseBack;
  var baseFront = placeModel.baseFront;
  var pxPerM = getScale(placeModel);
  var dimensions = PlaceClass$LumiDocuments.PlaceClass.axesModelDimensions(placeClass);
  if (up !== undefined) {
    var normBase = V2f$Linear.scaleTo(V2f$Linear.minus(baseBack, baseFront), 1);
    var perpCW = V2f$Linear.perpendicularCW(normBase);
    var perpBack = V2f$Linear.scale(perpCW, 0.5 * dimensions.widthBack * pxPerM);
    var perpFront = V2f$Linear.scale(perpCW, 0.5 * dimensions.widthFront * pxPerM);
    var upBack = V2f$Linear.scaleTo(up, 0.5 * dimensions.heightBack * pxPerM);
    var upFront = V2f$Linear.scaleTo(up, 0.5 * dimensions.heightFront * pxPerM);
    var front = V2f$Linear.minus(baseFront, V2f$Linear.scale(normBase, dimensions.baseFrontExtra * pxPerM));
    var back = V2f$Linear.plus(baseBack, V2f$Linear.scale(normBase, dimensions.baseBackExtra * pxPerM));
    return {
            topLeft: V2f$Linear.plus(V2f$Linear.plus(back, perpBack), upBack),
            bottomLeft: V2f$Linear.plus(V2f$Linear.plus(front, perpFront), upFront),
            bottomRight: V2f$Linear.plus(V2f$Linear.minus(front, perpFront), upFront),
            topRight: V2f$Linear.plus(V2f$Linear.minus(back, perpBack), upBack)
          };
  }
  var halfFrontWidth = V2f$Linear.scaleTo(V2f$Linear.minus(placeModel.side, baseFront), 0.5 * dimensions.widthFront * pxPerM);
  return {
          topLeft: V2f$Linear.plus(baseBack, halfFrontWidth),
          bottomLeft: V2f$Linear.plus(baseFront, halfFrontWidth),
          bottomRight: V2f$Linear.minus(baseFront, halfFrontWidth),
          topRight: V2f$Linear.minus(baseBack, halfFrontWidth)
        };
}

function mainPoint(param) {
  return param.baseFront;
}

var AxesModel = {
  transform: transform,
  codec: codec,
  getScale: getScale,
  toPoly: toPoly,
  mainPoint: mainPoint
};

function transform$1(param, hmirror, vflip, param$1) {
  var pmsHeight = param[1];
  var pmsWidth = param[0];
  var transformAbs = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? pmsWidth - x : x,
            vflip ? pmsHeight - y : y
          ];
  };
  var transformRel = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? -1 * x : x,
            vflip ? -1 * y : y
          ];
  };
  return {
          a: transformAbs(param$1.a),
          b: transformAbs(param$1.b),
          c: transformAbs(param$1.c),
          d: transformAbs(param$1.d),
          up: Core__Option.map(param$1.up, transformRel),
          width: param$1.width
        };
}

var codec$1 = Jzon.object6((function (param) {
        return [
                param.a,
                param.b,
                param.c,
                param.d,
                param.up,
                param.width
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  a: param[0],
                  b: param[1],
                  c: param[2],
                  d: param[3],
                  up: param[4],
                  width: param[5]
                }
              };
      }), Jzon.field("a", V2f$Linear.codec), Jzon.field("b", V2f$Linear.codec), Jzon.field("c", V2f$Linear.codec), Jzon.field("d", V2f$Linear.codec), Jzon.field("up", Jzon.nullable(V2f$Linear.codec)), Jzon.field("width", Jzon.$$float));

function getScale$1(param) {
  return V2f$Linear.length(V2f$Linear.minus(param.b, param.a)) / param.width;
}

function toAxesModel(param) {
  var d = param.d;
  var a = param.a;
  return {
          baseFront: V2f$Linear.plus(a, V2f$Linear.scale(V2f$Linear.minus(param.b, a), 0.5)),
          baseBack: V2f$Linear.plus(d, V2f$Linear.scale(V2f$Linear.minus(param.c, d), 0.5)),
          up: param.up,
          side: a,
          sideLength: param.width / 2
        };
}

function toPoly$1(qm, placeClass) {
  return toPoly(toAxesModel(qm), placeClass);
}

function toPolyQuadrilateral(quadModel, placeClass) {
  var up = quadModel.up;
  var d = quadModel.d;
  var c = quadModel.c;
  var b = quadModel.b;
  var a = quadModel.a;
  var topDownPoly = {
    topLeft: d,
    bottomLeft: a,
    bottomRight: b,
    topRight: c
  };
  if (up === undefined) {
    return topDownPoly;
  }
  var quadPoints = [
    a,
    b,
    c,
    d
  ];
  var height = PlaceClass$LumiDocuments.PlaceClass.quadrilateralModelHeight(placeClass);
  var pxPerM = getScale$1(quadModel);
  var h = V2f$Linear.scaleTo(up, pxPerM * height);
  var boxPoints = quadPoints.concat(quadPoints.map(function (extra) {
            return V2f$Linear.plus(h, extra);
          }));
  var mbOrientedBB = BoundingBox$Linear.orientedMinimumBoundingBox(boxPoints);
  if (mbOrientedBB === undefined) {
    return topDownPoly;
  }
  var match = BoundingBox$Linear.orientedBoundingBoxPoints(mbOrientedBB);
  return {
          topLeft: match[0],
          bottomLeft: match[3],
          bottomRight: match[2],
          topRight: match[1]
        };
}

function mainPoint$1(param) {
  return V2f$Linear.scale(V2f$Linear.plus(param.a, param.b), 0.5);
}

var QuadrilateralModel = {
  transform: transform$1,
  codec: codec$1,
  getScale: getScale$1,
  toAxesModel: toAxesModel,
  toPoly: toPoly$1,
  toPolyQuadrilateral: toPolyQuadrilateral,
  mainPoint: mainPoint$1
};

function axesToQuadrilateralModel(param) {
  var baseBack = param.baseBack;
  var baseFront = param.baseFront;
  var sf = V2f$Linear.minus(baseFront, param.side);
  return {
          a: V2f$Linear.minus(baseFront, sf),
          b: V2f$Linear.plus(baseFront, sf),
          c: V2f$Linear.plus(baseBack, sf),
          d: V2f$Linear.minus(baseBack, sf),
          up: param.up,
          width: param.sideLength * 2
        };
}

var codec$2 = LumiJzon.taggedVariant((function (placeModel) {
        if (placeModel.TAG === "AxesPlaceModel") {
          return [
                  "AxesPlaceModel",
                  Jzon.encodeWith(placeModel._0, codec)
                ];
        } else {
          return [
                  "QuadrilateralPlaceModel",
                  Jzon.encodeWith(placeModel._0, codec$1)
                ];
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "AxesPlaceModel" :
              return Core__Result.map(Jzon.decodeWith(json, codec), (function (x) {
                            return {
                                    TAG: "AxesPlaceModel",
                                    _0: x
                                  };
                          }));
          case "QuadrilateralPlaceModel" :
              return Core__Result.map(Jzon.decodeWith(json, codec$1), (function (x) {
                            return {
                                    TAG: "QuadrilateralPlaceModel",
                                    _0: x
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

function transform$2(placeModelSpaceSize, hmirror, vflip, placeModel) {
  if (placeModel.TAG === "AxesPlaceModel") {
    return {
            TAG: "AxesPlaceModel",
            _0: transform(placeModelSpaceSize, hmirror, vflip, placeModel._0)
          };
  } else {
    return {
            TAG: "QuadrilateralPlaceModel",
            _0: transform$1(placeModelSpaceSize, hmirror, vflip, placeModel._0)
          };
  }
}

function toPoly$2(placeModel) {
  if (placeModel.TAG === "AxesPlaceModel") {
    var axesModel = placeModel._0;
    return function (extra) {
      return toPoly(axesModel, extra);
    };
  }
  var quadModel = placeModel._0;
  return function (extra) {
    return toPoly$1(quadModel, extra);
  };
}

function mainPoint$2(placeModel) {
  if (placeModel.TAG === "AxesPlaceModel") {
    return mainPoint(placeModel._0);
  } else {
    return mainPoint$1(placeModel._0);
  }
}

var PlaceModel = {
  codec: codec$2,
  transform: transform$2,
  toPoly: toPoly$2,
  mainPoint: mainPoint$2
};

var $$default = [
  800,
  600
];

var include = LumiReact.Context.Make({
      $$default: $$default
    });

var useContext = include.useContext;

var SpaceSize_context = include.context;

var SpaceSize_Provider = include.Provider;

var SpaceSize = {
  $$default: $$default,
  context: SpaceSize_context,
  useContext: useContext,
  Provider: SpaceSize_Provider
};

function scale(baseFront, side, sideLength) {
  return V2f$Linear.length(V2f$Linear.minus(side, baseFront)) / sideLength;
}

function PlaceModel$SVG$AxesModel$Base(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-base",
            true
          ]].concat(classList));
  var match = V2$Linear.map(props.baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(props.baseBack, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Base = {
  make: PlaceModel$SVG$AxesModel$Base
};

function PlaceModel$SVG$AxesModel$Side(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-side",
            true
          ]].concat(classList));
  var match = V2$Linear.map(props.baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(props.side, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Side = {
  make: PlaceModel$SVG$AxesModel$Side
};

function PlaceModel$SVG$AxesModel$Up(props) {
  var __classList = props.classList;
  var baseFront = props.baseFront;
  var classList = __classList !== undefined ? __classList : [];
  var scale$1 = scale(baseFront, props.side, props.sideLength);
  var absoluteUp = V2f$Linear.plus(baseFront, V2f$Linear.scaleTo(props.up, scale$1 * 0.5));
  var className = LumiReact.classList([[
            "place-model-up",
            true
          ]].concat(classList));
  var match = V2$Linear.map(baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(absoluteUp, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Up = {
  make: PlaceModel$SVG$AxesModel$Up
};

function PlaceModel$SVG$AxesModel(props) {
  var __upClassList = props.upClassList;
  var __sideClassList = props.sideClassList;
  var __baseClassList = props.baseClassList;
  var match = props.axesModel;
  var sideLength = match.sideLength;
  var side = match.side;
  var baseBack = match.baseBack;
  var baseFront = match.baseFront;
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var sideClassList = __sideClassList !== undefined ? __sideClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  var match$1 = V2$Linear.map(baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$2 = V2$Linear.map(baseBack, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("line", {
                      className: "place-model-click-target",
                      vectorEffect: "non-scaling-stroke",
                      x1: match$1[0],
                      x2: match$2[0],
                      y1: match$1[1],
                      y2: match$2[1]
                    }),
                JsxRuntime.jsx(PlaceModel$SVG$AxesModel$Base, {
                      baseFront: baseFront,
                      baseBack: baseBack,
                      classList: baseClassList
                    }),
                JsxRuntime.jsx(PlaceModel$SVG$AxesModel$Side, {
                      baseFront: baseFront,
                      side: side,
                      classList: sideClassList
                    }),
                LumiReact.renderOption(undefined, match.up, (function (up) {
                        return JsxRuntime.jsx(PlaceModel$SVG$AxesModel$Up, {
                                    baseFront: baseFront,
                                    side: side,
                                    sideLength: sideLength,
                                    up: up,
                                    classList: upClassList
                                  });
                      }))
              ],
              className: "axes-model"
            });
}

var AxesModel$1 = {
  scale: scale,
  Base: Base,
  Side: Side,
  Up: Up,
  make: PlaceModel$SVG$AxesModel
};

function scale$1(a, b, width) {
  return V2f$Linear.length(V2f$Linear.minus(b, a)) / width;
}

function PlaceModel$SVG$QuadrilateralModel$Polyline(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-polyline",
            true
          ]].concat(classList));
  var points = props.points.map(function (param) {
          return param[0].toString() + "," + param[1].toString();
        }).join(" ");
  return JsxRuntime.jsx("polyline", {
              className: className,
              points: points,
              vectorEffect: "non-scaling-stroke"
            });
}

var Polyline = {
  make: PlaceModel$SVG$QuadrilateralModel$Polyline
};

function PlaceModel$SVG$QuadrilateralModel$Up(props) {
  var __classList = props.classList;
  var b = props.b;
  var a = props.a;
  var classList = __classList !== undefined ? __classList : [];
  var scale$2 = scale$1(a, b, props.width);
  var mainPoint = V2f$Linear.scale(V2f$Linear.plus(a, b), 0.5);
  var absoluteUp = V2f$Linear.plus(mainPoint, V2f$Linear.scaleTo(props.up, scale$2 * 0.5));
  var className = LumiReact.classList([[
            "place-model-up",
            true
          ]].concat(classList));
  var match = V2$Linear.map(mainPoint, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(absoluteUp, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Up$1 = {
  make: PlaceModel$SVG$QuadrilateralModel$Up
};

function PlaceModel$SVG$QuadrilateralModel(props) {
  var __upClassList = props.upClassList;
  var __baseClassList = props.baseClassList;
  var match = props.quadModel;
  var width = match.width;
  var b = match.b;
  var a = match.a;
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(PlaceModel$SVG$QuadrilateralModel$Polyline, {
                      points: [
                        a,
                        b,
                        match.c,
                        match.d,
                        a
                      ],
                      classList: baseClassList
                    }),
                LumiReact.renderOption(undefined, match.up, (function (up) {
                        return JsxRuntime.jsx(PlaceModel$SVG$QuadrilateralModel$Up, {
                                    a: a,
                                    b: b,
                                    width: width,
                                    up: up,
                                    classList: upClassList
                                  });
                      }))
              ]
            });
}

var QuadrilateralModel$1 = {
  scale: scale$1,
  Polyline: Polyline,
  Up: Up$1,
  make: PlaceModel$SVG$QuadrilateralModel
};

function PlaceModel$SVG$Circle(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var placeModelSpaceSize = useContext();
  var className = LumiReact.classList([[
            "place-model-circle",
            true
          ]].concat(classList));
  var match = V2$Linear.map(props.mainPoint, (function (prim) {
          return prim.toString();
        }));
  var r = (function (x) {
        return (x / 100).toString();
      })(V2f$Linear.length(placeModelSpaceSize));
  return JsxRuntime.jsx("circle", {
              className: className,
              cx: match[0],
              cy: match[1],
              r: r,
              vectorEffect: "non-scaling-stroke"
            });
}

var Circle = {
  make: PlaceModel$SVG$Circle
};

function PlaceModel$SVG$Arc(props) {
  var __classList = props.classList;
  var fraction = props.fraction;
  var mainPoint = props.mainPoint;
  var classList = __classList !== undefined ? __classList : [];
  var placeModelSpaceSize = useContext();
  var radius = (function (x) {
        return x / 100;
      })(V2f$Linear.length(placeModelSpaceSize));
  var r = radius.toString();
  var match = V2$Linear.map(mainPoint, (function (prim) {
          return prim.toString();
        }));
  var cy = match[1];
  var cx = match[0];
  var className = LumiReact.classList([[
            "place-model-arc",
            true
          ]].concat(classList));
  var tmp;
  if (fraction > 0.99) {
    tmp = JsxRuntime.jsx("circle", {
          className: "place-model-arc-circle",
          cx: cx,
          cy: cy,
          r: r,
          vectorEffect: "non-scaling-stroke"
        });
  } else {
    var arcRefPoint_1 = -1 * radius;
    var arcRefPoint = [
      0,
      arcRefPoint_1
    ];
    var angle = Math.PI * 2 * fraction;
    var start = V2f$Linear.plus(mainPoint, arcRefPoint);
    var end = V2f$Linear.plus(mainPoint, M22f$Linear.Mult.v2f(M22f$Linear.Transformation.rotation(angle), arcRefPoint));
    var match$1 = V2$Linear.map(start, (function (prim) {
            return prim.toString();
          }));
    var startY = match$1[1];
    var startX = match$1[0];
    var largeArc = fraction >= 0.5 ? "1" : "0";
    var sweep = "1";
    var match$2 = V2$Linear.map(end, (function (prim) {
            return prim.toString();
          }));
    var endY = match$2[1];
    var endX = match$2[0];
    var d = "M " + cx + " " + cy + " L " + startX + " " + startY + " A " + r + " " + r + " 0 " + largeArc + " " + sweep + " " + endX + " " + endY + " Z";
    var d$1 = "M " + startX + " " + startY + " A " + r + " " + r + " 0 " + largeArc + " " + sweep + " " + endX + " " + endY;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("path", {
                  className: "place-model-arc-fill",
                  d: d,
                  vectorEffect: "non-scaling-stroke"
                }),
            JsxRuntime.jsx("path", {
                  className: "place-model-arc-edge",
                  d: d$1,
                  vectorEffect: "non-scaling-stroke"
                })
          ]
        });
  }
  return JsxRuntime.jsx("g", {
              children: tmp,
              className: className
            });
}

var Arc = {
  make: PlaceModel$SVG$Arc
};

function PlaceModel$SVG$Label(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var placeModelSpaceSize = useContext();
  var className = LumiReact.classList([
          [
            "place-model-label",
            true
          ],
          [
            "is-family-monospace",
            true
          ],
          [
            "is-unselectable",
            true
          ]
        ].concat(classList));
  var match = V2$Linear.map(props.mainPoint, (function (prim) {
          return prim.toString();
        }));
  var fontSize = (function (x) {
        return (x / 150 | 0).toString();
      })(V2f$Linear.length(placeModelSpaceSize));
  return JsxRuntime.jsx("text", {
              children: props.label,
              className: className,
              dominantBaseline: "central",
              fontSize: fontSize,
              textAnchor: "middle",
              x: match[0],
              y: match[1]
            });
}

var Label = {
  make: PlaceModel$SVG$Label
};

function PlaceModel$SVG$Base(props) {
  var __classList = props.classList;
  var placeModel = props.placeModel;
  var classList = __classList !== undefined ? __classList : [];
  var classList$1 = [[
        "place-model",
        true
      ]].concat(classList);
  if (placeModel.TAG === "AxesPlaceModel") {
    var match = placeModel._0;
    return JsxRuntime.jsx(PlaceModel$SVG$AxesModel$Base, {
                baseFront: match.baseFront,
                baseBack: match.baseBack,
                classList: classList$1
              });
  }
  var match$1 = placeModel._0;
  var a = match$1.a;
  return JsxRuntime.jsx(PlaceModel$SVG$QuadrilateralModel$Polyline, {
              points: [
                a,
                match$1.b,
                match$1.c,
                match$1.d,
                a
              ],
              classList: classList$1
            });
}

var Base$1 = {
  make: PlaceModel$SVG$Base
};

function PlaceModel$SVG(props) {
  var __labelClassList = props.labelClassList;
  var __arcClassList = props.arcClassList;
  var __circleClassList = props.circleClassList;
  var __upClassList = props.upClassList;
  var __sideClassList = props.sideClassList;
  var __baseClassList = props.baseClassList;
  var __classList = props.classList;
  var placeModel = props.placeModel;
  var classList = __classList !== undefined ? __classList : [];
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var sideClassList = __sideClassList !== undefined ? __sideClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  var circleClassList = __circleClassList !== undefined ? __circleClassList : [];
  var arcClassList = __arcClassList !== undefined ? __arcClassList : [];
  var labelClassList = __labelClassList !== undefined ? __labelClassList : [];
  var classList$1 = [[
        "place-model",
        true
      ]].concat(classList);
  var mainPoint$3 = mainPoint$2(placeModel);
  var tmp;
  tmp = placeModel.TAG === "AxesPlaceModel" ? JsxRuntime.jsx(PlaceModel$SVG$AxesModel, {
          axesModel: placeModel._0,
          baseClassList: baseClassList,
          sideClassList: sideClassList,
          upClassList: upClassList
        }) : JsxRuntime.jsx(PlaceModel$SVG$QuadrilateralModel, {
          quadModel: placeModel._0,
          baseClassList: baseClassList,
          sideClassList: sideClassList,
          upClassList: upClassList
        });
  return JsxRuntime.jsxs("g", {
              children: [
                tmp,
                JsxRuntime.jsx(PlaceModel$SVG$Circle, {
                      mainPoint: mainPoint$3,
                      classList: circleClassList
                    }),
                LumiReact.renderOption(undefined, props.arcFraction, (function (fraction) {
                        return JsxRuntime.jsx(PlaceModel$SVG$Arc, {
                                    mainPoint: mainPoint$3,
                                    fraction: fraction,
                                    classList: arcClassList
                                  });
                      })),
                LumiReact.renderOption(undefined, props.label, (function (label) {
                        return JsxRuntime.jsx(PlaceModel$SVG$Label, {
                                    mainPoint: mainPoint$3,
                                    classList: labelClassList,
                                    label: label
                                  });
                      }))
              ],
              className: LumiReact.classList(classList$1),
              onClick: props.onClick
            });
}

var SVG = {
  SpaceSize: SpaceSize,
  AxesModel: AxesModel$1,
  QuadrilateralModel: QuadrilateralModel$1,
  Circle: Circle,
  Arc: Arc,
  Label: Label,
  Base: Base$1,
  make: PlaceModel$SVG
};

export {
  AxesModel ,
  QuadrilateralModel ,
  axesToQuadrilateralModel ,
  PlaceModel ,
  SVG ,
}
/*  Not a pure module */
